<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <template v-if="disponibilidadDominios.disponible === true">
          <v-card flat color="#EEEEEE">
            <v-col cols="12" lg="12">
              <h1 class="mobile-h1">¡El dominio se encuentra disponible!</h1>
            </v-col>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" lg="8" md="12">
                <template v-if="disponibilidadDominios.disponible === true">
                  <v-list-item two-line v-for="(item, i) in items" :key="i">
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        :color="item.color"
                        v-text="item.icon"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="font">{{
                        disponibilidadDominios.dominio
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="precio">
                        {{ disponibilidadDominios.simbolo }}
                        {{ disponibilidadDominios.precio }}.00/año
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-if="mensaje.length > 0 && response != 500">
                  <p class="parrafo1 ma-5">
                    Si necesita ayuda, puede comunicarse con nosotros al número
                    (505) 2278 3138, (505) 2278 314
                  </p>
                </template>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                class="d-flex align-end flex-column pr-6 mt-10"
                md="10"
              >
                <!-- <template v-if="disponibilidadDominios.disponible === true">
                  <div>
                    <v-btn
                      class="btn-reg white--text mb-6 mobile"
                      tile
                      color="#7CC609"
                      upercase
                      x-large
                      :disabled="desactivar"
                      @click="
                        agregar(
                          DisponibleDom,
                          disponibilidadDominios.simbolo,
                          disponibilidadDominios.precio,
                          disponibilidadDominios.idZona,
                          disponibilidadDominios.idNivel
                          
                        ),
                        desactivar = true
                      "
                    >
                      {{ registerLabel }}
                    </v-btn>
                  </div>
                </template> -->
              </v-col>
            </v-row>
          </v-card>
        </template>
        <template v-else-if="disponibilidadDominios.disponible === false">
          <v-card flat color="#EEEEEE">
            <v-col cols="12" lg="12">
              <h1 class="mobile-h1">¡El dominio no se encuentra disponible!</h1>
              Lamentamos, no disponer lo que busca, puede seguir buscando con
              distintas combinaciones de texto.
            </v-col>
            <v-divider></v-divider>
            <v-row>
              <v-col cols="12" lg="8" md="12">
                <template v-if="disponibilidadDominios.disponible === false">
                  <v-list-item
                    two-line
                    v-for="(item, i) in itemsNoDisponible"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon
                        x-large
                        :color="item.color"
                        v-text="item.icon"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      {{ disponibilidadDominios.dominio }}
                    </v-list-item-content>
                    
                    
                  </v-list-item>
                  </template>
                <p class="parrafo1 mt-5 mx-5">
                  Cliente: {{ disponibilidadDominios.nombreCliente}}                  
                  </p>
                 <p class="parrafo1 mx-5">
                    Dirección: {{ disponibilidadDominios.direccion}}
                    </p>
                 <p class="parrafo1 mb-5 mx-5">
                     Vence: {{ disponibilidadDominios.fechaExpiracion}}
                  </p>                 
    
                <p class="parrafo1 ma-5">
                  Si necesita ayuda, puede comunicarse con nosotros al número
                  (505) 2278 3138, (505) 2278 314
                </p>
              </v-col>
              <v-col
                cols="12"
                lg="4"
                class="d-flex align-end flex-column pr-6 mt-10"
                md="10"
              >
                <!-- <template v-if="disponibilidadDominios.disponible === true">
                  <v-btn
                    class="btn-reg white--text mb-6 mobile"
                    tile
                    color="#7CC609"
                    upercase
                    x-large
                    v-on:click="compradominiosugeridostore(item.dominio)"
                  >
                    {{ registerLabel }}
                  </v-btn>
                </template> -->
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
      <!-- Resumen de pedido desk -->
      <v-col
        v-if="resumensolicitud.length > 0"
        class="ml-10 cardMovil lighten-5"
        id="card-desk"
        cols="12"
        lg="4"
        md="12"
      >
        <template class="sticky">
          <v-card
            elevation="2"
            class="justify-space-around"
            color="deep-orange accent-3"
            outlined
            min-height="80"
            dark
          >
            <v-card-title
              class="mt-2 color-text d-flex justify-center custom-transform-class"
            >
              <v-icon class="mr-1">mdi-cart-plus</v-icon>
              Resumen de la Solicitud</v-card-title
            >
          </v-card>
          <v-card
            id="card-desk"
            color="#EEEEEE"
            flat
            min-height="80"
            min-width="250"
          >
            <div v-for="(dom, item) in resumensolicitud" :key="item">
              <v-row>
                <v-col class="d-flex justify-end" cols="12" lg="4" md="12">
                  <v-btn icon>
                    <v-icon
                      class="iconMobile"
                      color="#616161"
                      v-on:click="removeDomain(dom.dominio)"
                    >
                      mdi-delete</v-icon
                    >
                  </v-btn>

                  <v-list-item-title class="ml-2 ">{{
                    dom.dominio
                  }}</v-list-item-title>
                </v-col>
                <v-col class="ml-14" cols="12" lg="4">
                  <v-list-item-title class=" d-flex mr-1 flex-row-reverse">{{
                    dom.moneda + "" + dom.precioReal.toFixed(2)
                  }}</v-list-item-title>
                </v-col>
              </v-row>
            </div>

            <v-divider></v-divider>
            <v-row>
              <v-col class="d-flex justify-end" cols="12" lg="6">
                <v-list-item-title class="ml-2 total">Total</v-list-item-title>
              </v-col>
              <v-col class="ml-14" cols="12" lg="4">
                <v-list-item-title class="total d-flex mr-1 flex-row-reverse"
                  >${{ total }}</v-list-item-title
                >
              </v-col>
            </v-row>
            <v-row>
              <div class="text-center">
                <v-dialog
                  v-model="dialog"
                  transition="dialog-bottom-transition"
                  max-width="600"
                  persistent
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-col cols="12" class="d-flex float-right" lg="5" md="12">
                      <v-btn
                        class="btn-reg white--text d-flex justify-center mobile"
                        tile
                        color="blue-grey darken-4"
                        upercase
                        x-large
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="solicitarAprobacion"
                      >
                        Enviar Solicitud
                      </v-btn>
                    </v-col>
                  </template>

                  <v-card>
                    <v-card-title
                      class="text-h5 white--text blue-grey darken-4"
                    >
                      Solicitud enviada
                    </v-card-title>

                    <v-card-text class="text-h5 text-justify">
                      Estimado usuario, la solicitud estará siendo antendida por
                      nuestros técnicos, los cuales le daran respuesta en un
                      lapso 24 horas
                    </v-card-text>
                    <span
                      >hacer click en el botón aceptar para ir a su panel de
                      administración</span
                    >
                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="#7CC609"
                        class="white--text"
                        @click="dialog = false"
                        :to="{ name: 'admin-dominios' }"
                      >
                        Aceptar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <!-- modal para enviar solicitud de aprobacion -->
    <!-- lista -->

    <template v-if="dominiosSugeridos.length > 0">
      <h4 class="ma-3 font2">Hemos encontrado estos dominios para usted</h4>
      <v-row v-for="(item, i) in dominiosSugeridos" :key="'C' + i">
        <v-col cols="12" class="py-0">
          <v-card flat outlined>
            <v-row>
              <v-col cols="12" lg="6" md="12" class="d-flex align-center">
                <p class="ma-1 text-negro text-left">{{ item.dominio }}</p>
              </v-col>
              <v-divider></v-divider>
              <v-col cols="12" lg="3" md="12" class="d-flex align-center">
                <p class="ma-1 text-negro">
                  {{ valordominiosugerido(item.moneda, item.precio) }}
                </p>
              </v-col>
              <v-col
                cols="12"
                class="d-flex align-end  pr-6 flex-column"
                lg="3"
                md="12"
              >
                <!-- <v-btn
                  class="btn-reg white--text d-flex mobile"
                  tile
                  color="#7CC609"
                  upercase
                  x-large
                  v-on:click="eliminarSugeridosAgregados(item.dominio),agregar(item.dominio, item.simbolo, item.precio, item.idZona, item.idNivel)"
                >
                  {{ registerLabel }}
                </v-btn> -->
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="75"
          color="#007BFF"
        ></v-progress-circular>
      </v-overlay> -->
    </template>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import Vue from "vue";
import "vue-toast-notification/dist/theme-sugar.css";
export default {
  mounted() {
    // this.agregarlista();
    let tempDomain = localStorage.getItem("tempDomain");
    let tempZone = localStorage.getItem("tempZone");
    let tempZoneId = localStorage.getItem("tempZoneId");

    if (tempDomain) {
      this.availabledomainsNavigate(tempDomain, tempZone, tempZoneId);
    }

    let datastorage = localStorage.getItem("localdomain");
    if (datastorage) {
      this.disponibilidadDominios = JSON.parse(datastorage);
      this.resumensolicitud = JSON.parse(datastorage);
      console.log("resumen", this.disponibilidadDominios);
    } else this.disponibilidadDominios = [];

    this.dominiosList = JSON.parse(localStorage.getItem("dominiosList"));
    if (!this.dominiosList) this.dominiosList = [];
  },
  name: "DominioDetalle",
  components: {},
  props: {
    mensaje: String,
    response: Number,
  },
  computed: {
    ...mapGetters(["active", "getdomain", "getzone", "getzoneid"]),
    domainToFind: {
      get() {
        return this.$store.state.domain;
      },
    },
    valordominio: function() {
      return (
        this.disponibilidadDominios.simbolo +
        "" +
        this.disponibilidadDominios.precio.toFixed(2) +
        "/año"
      );
    },
    zoneToFind: {
      get() {
        return this.$store.state.zone;
      },
    },
    total: function() {
      if (this.resumensolicitud.length > 0) {
        let sum = this.resumensolicitud
          .map((o) => o.precioReal)
          .reduce((a, c) => {
            return a + c;
          });
        return sum.toFixed(2);
      }
      return 0;
    },
  },
  watch: {
    domainToFind(newValue) {
      this.dominioModel.dominio = newValue;
      this.availabledomains(newValue);
    },
    zoneToFind() {
      this.dominioModel.dominio = this.$store.state.domain;
      this.availabledomains(this.dominioModel.dominio);
    },
  },
  data: () => ({
    // overlay: false,
    dialog: false,
    title: "Buscar Dominio",
    dominioModel: {
      dominio: "",
      zona: "",
      idZona: 0,
    },
    item: [],
    dominiosList: [],
    compradominiostoreState: false,
    registerLabel: "Agregar",
    dominio: {
      dominio: "",
      zona: "",
      idZona: 0,
      precio: 0,
      simbolo: "",
    },
    solicitudAprobacionDominioDetalles: [],
    disponibilidadDominios: {},
    DisponibleDom: "",
    dominiosSugeridos: [],
    resumensolicitud: [],
    tokenOP: "",
    items: [
      {
        text: "dominio.com.ni",
        icon: "mdi-checkbox-marked-circle",
        subtitle: "$50.00/año",
        color: "#7CC609",
      },
    ],
    itemsNoDisponible: [
      {
        icon: "mdi-close-circle",
        color: "#cf0404",
      },
    ],
    desactivar: false,
    newSugeridos: [],
    newSugeridosRecibe: [],
    newResumen: [],
    newDisp: [],
  }),
  methods: {
    // async create(){
    //   const solicitudAprobacionDominioDetalles = {
    //     method: "POST",
    //     headers: {"Content-Type": "application/json"},
    //     body: JSON.stringify({ title: "enviar solicitud"})

    //   };
    //   const response = await fetch("SOLICITUD_APROBACION", solicitudAprobacionDominioDetalles);

    // },

    solicitarAprobacion: async function() {
      // this.overlay = true;
      console.log("resumen solicitud: ", this.resumensolicitud);

      for (let i = 0; i < this.resumensolicitud.length; i++) {
        let element = this.resumensolicitud[i];

        this.solicitudAprobacionDominioDetalles.push({
          dominio: element.dominio,
          idZona: element.idZona,
          idNivel: element.idNivel,
          moneda: element.moneda,
          precioReal: element.precioReal,
        });
      }

      console.log("Envio: ", this.solicitudAprobacionDominioDetalles);

      let send = {
        solicitudAprobacionDominioDetalles: this
          .solicitudAprobacionDominioDetalles,
      };

      console.log("por aca", this.solicitudAprobacionDominioDetalles);
      let token = localStorage.getItem(atob("usr"));
      this.$http.defaults.headers.common.Authorization = "Bearer " + token;
      await this.$http
        .post(this.$keys("SOLICITUD_APROBACION"), send)
        .then((response) => {
          if (response.status === 200) {
            //this.toasterMessageResponse(response.data.mensaje, "success");

            localStorage.removeItem("localdomain");
            this.resumensolicitud = [];

            // alert('La solicitud se a enviado correctamente')
            // this.overlay = false
          }
        });
    },
    toasterMessage: function(tipo) {
      let message = "";
      let type = "";
      switch (tipo) {
        case 1:
          message = "correo reenviado con exito";
          type = "success";
          break;
        case 2:
          message = "dominio eliminado";
          type = "info";
          break;
      }
      Vue.$toast.open({
        message: message,
        type: type,
      });
    },
    toasterMessageResponse: function(msg) {
      Vue.$toast.open({
        message: msg,
        type: "error",
      });
    },
    // agregarlista(){
    //   console.log("Hola que ace");
    //   let datastorage = localStorage.getItem("localdomain");
    //   this.resumensolicitud = JSON.parse(datastorage);
    //   console.log("Resumen: ",this.resumensolicitud);
    // },
    addToList() {
      let datastorage = localStorage.getItem("localdomain");

      this.disponibilidadDominios = JSON.parse(datastorage);
      this.resumensolicitud = JSON.parse(datastorage);
      this.stepOneDisable = false;
    },
    addDomainToList(newDomain) {
      this.$emit("addToList", newDomain);
    },
    agregar: function(dominio, moneda, precioReal, idZona, idNivel) {
      let localInicio = localStorage.getItem("dominioInicio");

      console.log(localInicio);

      if (dominio == "") {
        dominio = localInicio;
        this.dominiosSugeridos.splice(0, 1);
        // this.desactivar == true;
        console.log("Eliminar dominio: ", this.dominiosSugeridos);
      }

      this.newResumen.push({
        dominio: dominio,
        idNivel: idNivel,
        idZona: idZona,
        moneda: moneda,
        precioReal: precioReal,
      });

      let dominiocompose1 = dominio;
      let dominioFilter1 = dominiocompose1.split(".");
      let [dominioClean] = dominioFilter1;

      this.resumensolicitud.push({
        dominio: dominioClean,
        idNivel: idNivel,
        idZona: idZona,
        moneda: moneda,
        precioReal: precioReal,
      });

      console.log("carrito: ", this.resumensolicitud);
      let array = JSON.stringify(this.resumensolicitud);
      localStorage.setItem("localdomain", array);
      localStorage.removeItem("dominioInicio");
    },

    eliminarSugeridosAgregados(dominio) {
      const index = this.dominiosSugeridos.findIndex(
        (x) => x.dominio === dominio
      );
      this.dominiosSugeridos.splice(index, 1);
    },

    compradominiosugeridostore: function(dominio) {
      const index = this.dominiosSugeridos.findIndex(
        (x) => x.dominio === dominio
      );
      this.addDomainToList(JSON.stringify(this.dominiosSugeridos[index]));
      this.dominiosSugeridos.splice(index, 1);
      let datastorage = localStorage.getItem("localdomain");
      this.resumensolicitud = JSON.parse(datastorage);
      console.log(this.resumensolicitud);
    },
    compradominiostore: function() {
      this.addDomainToList(JSON.stringify(this.disponibilidadDominios));
      this.compradominiostoreState = true;
    },
    selectedDomain: function(domain, currency, amount) {
      this.$store.commit("domainToPurchase", domain);
      this.$store.commit("domainCurrency", currency);
      this.$store.commit("domainPrice", amount);
      //window.location.href ="compradominio";
    },
    removeDomain: function(value) {
      const index = this.resumensolicitud.findIndex((x) => x.dominio === value);
      this.resumensolicitud.splice(index, 1);
      localStorage.removeItem("localdomain");
      localStorage.setItem(
        "localdomain",
        JSON.stringify(this.resumensolicitud)
      );
    },
    valordominiosugerido: function(moneda, valor) {
      return (moneda == "Dólar" ? "$" : "C$") + "" + valor.toFixed(2) + "/año";
    },
    availabledomains: async function() {
      await this.$http
        .get(
          this.$keys("DISPONIBILIDAD_DOMINIO") +
            "?dominio=" +
            this.dominioModel.dominio +
            "&zona=" +
            this.getzone +
            "&idZona=" +
            this.getzoneid
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.disponibilidadDominios) {
              this.disponibilidadDominios =
                response.data.disponibilidadDominios[0];
              console.log("disponibles: ", this.disponibilidadDominios);
              this.dominiosSugeridos = response.data.disponibilidadDominios.filter(
                (x) => x.sugerido === true
              );
              let localInicio = localStorage.getItem("dominioInicio");

              console.log(localInicio);

              if (localInicio == "") {
                this.DisponibleDom = localInicio;
              } else {
                this.DisponibleDom =
                  response.data.disponibilidadDominios[0].dominio;
              }

              // console.log("sugeridos: ", this.DisponibleDom);

              // this.arrayDisponible.push({
              //   disponible: true,
              //   dominio: this.disponibilidadDominios.dominio,
              //   idMoneda: this.disponibilidadDominios.idMoneda,
              //   idNivel: this.disponibilidadDominios.idNivel,
              //   idZona: this.disponibilidadDominios.idZona,
              //   moneda: this.disponibilidadDominios.moneda,
              //   nivel: this.disponibilidadDominios.nivel,
              //   precio: this.disponibilidadDominios.precio,
              //   simbolo: this.disponibilidadDominios.simbolo,
              //   sugerido: this.disponibilidadDominios.sugerido,
              //   zona: this.disponibilidadDominios.zona
              // })
              // console.log("array Discponible: ", this.arrayDisponible);
              // console.log("disponibles: ", disponibilidadDominios);
              // console.log("hola");
              this.tokenOP = response.data.tokenOP;
            }
          }
        })
        .catch((error) => {
          console.log("entro aca");
          if (error.response.status === 409) {
            this.err = true;
            this.toasterMessageResponse(error.response.data.mensaje, "error");
          }
        });
    },
    availabledomainsNavigate: async function(tempDomain, tempZone, tempZoneId) {
      await this.$http
        .get(
          this.$keys("DISPONIBILIDAD_DOMINIO") +
            "?dominio=" +
            tempDomain +
            "&zona=" +
            tempZone +
            "&idZona=" +
            tempZoneId
        )
        .then((response) => {
          if (response.status === 200) {
            if (response.data.disponibilidadDominios) {
              this.disponibilidadDominios =
                response.data.disponibilidadDominios[0];
              // this.dominiosSugeridos = response.data.disponibilidadDominios.filter(
              //   (x) => x.sugerido === true
              // );

              this.dominiosSugeridos = response.data.disponibilidadDominios.map(
                (obj) => ({
                  ...obj,
                  dns: obj.dominio.substring(0, obj.dominio.indexOf(".")),
                })
              );
              this.tokenOP = response.data.tokenOP;
            }
          }
        })
        .catch((error) => {
          if (error.response.status === 0) {
            console.log();
          }
        });
      localStorage.removeItem("tempDomain");
      localStorage.removeItem("tempZone");
      localStorage.removeItem("tempZoneId");
    },
  },
};
</script>

<style>
.zonas {
  color: #17a2b8;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
.cardMovil {
  margin-left: 0px;
}
.total {
  font-weight: 600;
  color: #000 !important;
  font-size: 20px !important;
}
.caja {
  border: 1px #616161 solid !important;
}
.bg {
  background: #f5f5f5 !important;
}
.mobile-h1 {
  font-size: 48px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  text-align: left;
  margin-top: 10px;
  color: #616161 !important;
}
@media (max-width: 460px) {
  .mobile-h1 {
    margin-top: 10px !important;
    color: #616161 !important;
  }
}
.v-chip:before {
  background-color: none !important;
}
.font {
  font-size: 26px;
  font-weight: 600;
  color: #616161 !important;
}
.font2 {
  font-size: 16px;
  font-weight: 600;
  color: #616161 !important;
}
.precio {
  line-height: 58px;
  font-size: 20px;
}
.parrafo1 {
  color: #616161 !important;
  margin-right: 10px !important;
}
.decoracion {
  text-decoration: none;
}
@media (max-width: 725px) {
  .mobile {
    margin-left: 10px !important;
  }
  .font {
    margin-top: 10px !important;
  }
}
.text-negro {
  color: #000 !important;
  font-size: 20px !important;
}
</style>
