<template>
  <v-container>
    <v-row>
      <v-col cols="12 p-10" class="top">
        <v-form class="mobile">
          <v-row no-gutters>
            <slot name="buscar">
              <v-col cols="12" lg="8" md="6" sm="12">
                <v-text-field
                  label="Escriba el dominio que desea registrar"
                  outlined
                  dark
                  v-model="domainRequest"
                  v-on:keyup="isDomainValid"
                >
                </v-text-field>
                <span v-show="domainErr" style="color:red">{{
                  domainError
                }}</span>
              </v-col>
            </slot>
            <v-col class="d-flex ml-1" cols="12" lg="3" md="3" sm="12">
              <v-select
                outlined
                v-model="zonaId"
                item-value="idZona"
                item-text="zona"
                id="zonas"
                :items="items"
                :rules="[(v) => !!v || 'Campo Obligatorio']"
                required
                dark
                chips
                filled
              >
              </v-select>
              <v-btn
                class="mr-2"
                color="#003791"
                elevation="9"
                large
                :disabled="domainErr"
                v-on:click="toggle"
              >
                BUSCAR
              </v-btn>
            </v-col>
          </v-row>
          <enviar-solicitud-aprobacion
            v-bind:mensaje="domainRequest"
            v-bind:response="response"
            @addToList="addToList"
          >
          </enviar-solicitud-aprobacion>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import EnviarSolicitudAprobacion from "@/components/EnviarSolicitudAprobacion";
import Vue from "vue";

export default {
  name: "buscar-dominio",
  components: { EnviarSolicitudAprobacion },
  props: {
    mensaje: String,
    response: Number,
    action: String,
  },
  data: () => ({
    loading: false,
    items: [],
    domainRequest: "",
    zona: "",
    zonaId: 0,
    dominiosList: [],
    reg: /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\\-]*[a-zA-Z0-9]))*$/,
    domainError: "El dominio ingresado no es valido",
    domainErr: false,
  }),
  mounted() {
    this.getZonas();
  },
  methods: {
    isDomainValid: function() {
      if (!this.reg.test(this.domainRequest)) {
        this.domainErr = true;
      } else {
        this.domainErr = false;
      }
    },
    toasterMessageResponse: function(msg) {
      Vue.$toast.open({
        message: msg,
        type: "error",
      });
    },
    domainExist: function(data) {
      let existe = false;
      let item = JSON.parse(localStorage.getItem("localdomain") || "[]");
      const index = item.findIndex((x) => x.dominio === data.dominio);
      if (index >= 0) {
        existe = true;
        return existe;
      }
      return existe;
    },
    addToList(value) {
      if (localStorage.getItem("localdomain")) {
        let item = JSON.parse(localStorage.getItem("localdomain") || "[]");

        let conver = JSON.parse(value);
        if (this.domainExist(conver)) {
          this.toasterMessageResponse(
            "El dominio ya fue agregado anteriormente."
          );
          return;
        }
        item.push({
          idZona: conver.idZona,
          idNivel: conver.idNivel,
          dominio: conver.dominio,
          precioReal: conver.precio,
          moneda: conver.simbolo,
          idDescuento: 0,
          montoDescuento: 0,
        });

        localStorage.setItem("localdomain", JSON.stringify(item));
      } else {
        let conver = JSON.parse(value);
        localStorage.setItem(
          "localdomain",
          JSON.stringify([
            {
              idZona: conver.idZona,
              idNivel: conver.idNivel,
              dominio: conver.dominio,
              precioReal: conver.precio,
              moneda: conver.simbolo,
              idDescuento: 0,
              montoDescuento: 0,
            },
          ])
        );
      }
      this.$emit("addToList", "");
         
    },
    toggle() {
      let mszonavacia = "Seleccione una zona por favor";
      if (this.domainRequest.length > 0) {
        if (this.action) {
          localStorage.setItem("tempDomain", this.domainRequest);
          localStorage.setItem('dominioInicio', this.domainRequest);
          let index = this.items.findIndex((x) => x.idZona === this.zonaId);
          if (index > -1) {
            localStorage.setItem("tempZone", this.items[index].zona);
            localStorage.setItem("tempZoneId", this.zonaId);
            window.location.href = "buscardominio";
          } else {
            this.toasterMessageResponse(mszonavacia);
          }
        } else {
          var index = this.items.findIndex((x) => x.idZona === this.zonaId);
          if (index > -1) {
            this.$store.commit("searchDomain", this.domainRequest);
            
            this.$store.commit("searchZone", this.items[index].zona);
            this.$store.commit("searchZoneId", this.items[index].idZona);
          } else {
            this.toasterMessageResponse(mszonavacia);
          }
        }
      } else {
        this.toasterMessageResponse("Escriba el nombre de su dominio");
      }
    },
    getZonas: async function() {
      await this.$http.get(this.$keys("ZONAS")).then((resp) => {
        if (resp.status == 200) {
          this.items = resp.data;
          this.zonaId = this.items[0].idZona;
        }
      });
    },
  },
};
</script>

<style>
.bg {
  background: #f5f5f5 !important;
}
.top {
  margin-top: -130px !important;
}
@media (max-width: 725px) {
  .top {
    margin-top: -190px !important;
  }
}

@media (max-width: 725px) {
  .mobile {
    margin-top: -120px !important;
  }
}
@media (max-width: 360px) {
  .mobile {
    margin-top: -0px !important;
  }
}
@media (max-width: 320px) {
  .mobile {
    margin-top: 0px !important;
  }
}

@media (max-width: 375px) {
  .mobile {
    margin-top: -20px !important;
  }
}
@media (max-width: 414px) {
  .mobile {
    margin-top: -20px !important;
  }
}
@media (max-width: 460px) {
  .v-application .display-2 {
    margin-top: 80px !important;
  }

  .v-application .title {
    display: none;
  }

  .v-btn:not(.v-btn--round).v-size--large {
    height: 55px !important;
    min-width: 55px !important;
    padding: 0 19.5555555556px;
    margin-left: 5px;
    color: #fff;
  }
}

.theme--light.v-label {
  color: #616161 !important;
}

.v-btn:not(.v-btn--round).v-size--large {
  height: 55px !important;
  min-width: 78px;
  padding: 0 19.5555555556px;
  margin-left: 5px;
  color: #fff;
}

.theme--light.v-input input {
  color: #616161 !important;
}

.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--box
  .v-select__selections,
.v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed
  .v-select__selections {
  min-height: 10px !important;
}

.theme--light.v-select .v-select__selection--comma {
  color: #000 !important;
}

.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: #fff !important;
}

/* .mdi-menu-down::before {
    content: "\F035D";
    color: #000 !important;
}
.v-list-item__title {
  
    color: #000 !important;
} */
.decoracion {
  text-decoration: none !important;
}
</style>
